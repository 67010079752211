@import 'milligram/dist/milligram.min.css';

body {
  margin: 0;
  font-family: Arial, sans-serif;


}

@media (max-width: 600px) {
  .container {
    padding: 2px;
  }
}

.MuiDataGrid-root {
  font-size: 16px !important;
}



@media (max-width:600px) {
  .MuiDataGrid-columnHeader {
    padding: 1px !important;
    white-space: nowrap!important;
    
    word-wrap: break-word !important;
    overflow: visible !important;
  }

  .MuiDataGrid-columnHeaderTitle {
    padding: 4px !important;
    white-space: nowrap !important;
    word-wrap: break-word !important;
    overflow: visible !important;
  }

  .MuiDataGrid-cell {
    padding: 4px !important;
  }

  .MuiDataGrid-root {
    font-size: 10px !important;
  }

  .MuiDataGrid-footerContainer {
    padding: 4px;
  }
}

ul {
  list-style: none
}


nav {
  overflow: hidden;
  background-color: lightgray;
  border-bottom: 1px #f4f4f4;
  margin-bottom: 20px;
  padding: 20px;

}

nav h2 {
  display: inline;
}

nav a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  text-decoration: underline;
  float: right;
}

nav a:hover {
  background-color: #ddd;
  color: black;
}

.container {
  max-width: 100%;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;

}

.flex-item {
  width: 24%;
  /* Each item takes up 25% of the container width */
  box-sizing: border-box;

  margin: 5px;
}

/* Add this CSS to your stylesheet */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}